.social-container {
  background: #424242;
  padding: 25px 50px;
  font-family: 'Lato', sans-serif;
}

a.social {
  margin: 0 1rem;
  padding-left: 50px;
  transition: transform 250ms;
  display: inline-block;
}

a.gmaps {
  margin: 0 1rem;
  padding-left: 5px;
  transition: transform 250ms;
  display: inline-block;
  color:white;
}

a.schedule {
  margin: 0 1rem;
  padding-left: 5px;
  transition: transform 250ms;
  display: inline-block;
  color:white;
}

a.gmaps:hover {
  transform: translateY(-2px);
  color:white;
}

a.schedule:hover {
  transform: translateY(-2px);
  color:white;
}

a.social:hover {
  transform: translateY(-2px);
}

.event-text-footer {
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 50px;
  text-align: justify;
  color:white;
}

.event-text-footer span {
  display: block;
}